<template>
  <modal-padrao
    ref="modal-requisitos-texto"
    max-width="80%"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo="$t('modulos.participantes.formulario.requisitos.texto')"
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-select
        v-model="form.tipoRequisito"
        class="col-12 col-md-4"
        :label="
          $t('modulos.participantes.formulario.requisitos.tipo_requisito')
        "
        :options="opcoes.tipoRequisito"
        obrigatorio
      />
      <input-textarea
        v-model="form.descricao"
        :rows="10"
        class="col-12 col-md-12"
        :label="$t('modulos.participantes.formulario.requisitos.descricao')"
        :max="10000"
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import _ from 'lodash';
export default {
  data() {
    return {
      edicao: false,
      form: {},
      formReferencia: {},
      opcoes: {
        tipoRequisito: helpers.TipoRequisitoEnum,
      },
    };
  },
  mounted() {
    this.iniciarForm();
  },
  methods: {
    iniciarForm: function () {
      this.form = {
        id: null,
        tipoRequisito: null,
        descricao: null,
      };
    },
    abrirModal: function (form) {
      this.iniciarForm();
      this.edicao = false;
      if (form) {
        this.edicao = true;
        this.formReferencia = form;
        this.form = _.cloneDeep(form);
        this.form.descricao = this.transformarDescricao(this.form.descricao);
      }
      this.$refs['modal-requisitos-texto'].abrirModal();
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      if (!this.edicao) this.$emit('salvar-novo', this.form);
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-requisitos-texto'].fecharModal();
    },
    confirmacaoSalvar: function () {
      if (this.edicao) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    transformarDescricao: function (descricao) {
      return descricao
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/?span[^>]*>/gi, '')
        .replace(/\s*<\/?[^>]+>\s*/g, '')
        .trim();
    }
  },
};
</script>
