<template>
  <div>
    <v-form ref="form">
      <div class="d-flex align-center justify-space-between">
        <div class="justify-start">
          <input-radio
            v-model="form.tipoParticipanteRadio"
            class="mt-3"
            :options="opcoes.tipoParticipante"
            :label="$t('modulos.participantes.formulario.tipo_participante')"
          />
        </div>

        <botao-padrao
          v-if="
            hasFlagParticipanteConta && form.tipoParticipante !== 'PessoaFisica'
          "
          @click="confirmarBuscarCNPJ"
        >
          <v-icon>$search</v-icon>
          {{ $t('geral.botoes.consulta_cnpj') }}
        </botao-padrao>
      </div>

      <accordion-padrao
        :titulo="$t('modulos.participantes.formulario.dados_cadastro')"
        :nao-retrair="true"
      >
        <div class="row">
          <input-text
            v-model="form.apelido"
            class="col-12 col-md-3"
            :label="$t('modulos.participantes.formulario.apelido')"
            obrigatorio
          />
          <input-text
            v-model="form.nome"
            class="col-12 col-md-3"
            :label="$t('modulos.participantes.formulario.nome')"
            obrigatorio
          />
          <input-select
            v-model="form.tipoParticipante"
            class="col-12 col-md-2"
            :label="$t('modulos.participantes.formulario.tipo_pessoa')"
            :options="opcoes.tipoPessoa"
            obrigatorio
            @change="limparDocumento"
          />
          <input-text
            v-model="form.documento"
            class="col-12 col-md-2"
            :label="form.tipoParticipante == 'PessoaFisica' ? 'CPF' : 'CNPJ'"
            :placeholder="
              form.tipoParticipante == 'PessoaFisica' ? 'CPF' : 'CNPJ'
            "
            :mascara="mascaraDoc"
            obrigatorio
          />
          <input-text
            v-model="form.telefone"
            class="col-12 col-md-2"
            :label="$t('modulos.participantes.formulario.telefone')"
            :mascara="mascaraTel"
          />
          <input-text
            v-model="form.email"
            class="col-12 col-md-3"
            :label="$t('modulos.participantes.formulario.email')"
            email
          />
          <input-text
            v-model="form.site"
            class="col-12 col-md-3"
            :label="$t('modulos.participantes.formulario.site')"
          />
          <input-text
            v-model="form.inscricaoEstadual"
            class="col-12 col-md-3"
            :label="$t('modulos.participantes.formulario.inscricao_estadual')"
            :disabled="ehCpf"
          />
          <input-text
            v-model="form.inscricaoMunicipal"
            class="col-12 col-md-3"
            :label="$t('modulos.participantes.formulario.inscricao_municipal')"
            :disabled="ehCpf"
          />
        </div>
      </accordion-padrao>
      <div class="row mt-3">
        <accordion-padrao
          v-if="form.tipoParticipanteRadio != 2"
          :class="{
            'col-12 col-md-7 mb-12': participanteLogadoConta,
            'col-12 col-md-9 mb-12': !participanteLogadoConta,
          }"
          :nao-retrair="true"
          :titulo="$t('modulos.participantes.formulario.dias_envio_material')"
        >
          <div class="d-flex align-center justify-space-between">
            <input-checkbox
              v-for="(dia, i) in opcoes.diasEnvioMaterial"
              :key="i"
              v-model="form[dia.value]"
              :label="dia.text"
            />
          </div>
        </accordion-padrao>
        <input-select
          v-if="form.tipoParticipanteRadio != 2 && participanteLogadoConta"
          v-model="form.validacaoCertificado"
          class="col-12 col-md-5"
          :label="$t('modulos.participantes.formulario.validacao_certificados')"
          :placeholder="$t('geral.inputs.selecione')"
          :options="opcoes.validacaoCertificado"
        />
        <accordion-padrao
          v-if="form.tipoParticipanteRadio != 1"
          :class="
            participanteLogadoConta ? 'col-4 mb-12' : 'col-12 col-md-12 mb-12'
          "
          :nao-retrair="true"
          :titulo="$t('modulos.participantes.formulario.papel_fornecedor')"
        >
          <div class="d-flex align-center papelFornecedoresCheckboxes">
            <input-checkbox
              v-for="(papel, i) in opcoes.papelFornecedor"
              :key="i"
              v-model="form[papel.value]"
              :label="papel.text"
            />
          </div>
        </accordion-padrao>

        <input-participantes
          v-if="participanteLogadoConta"
          v-model="form.participanteResponsavelFaturamento"
          class="col-12 col-md-3"
          :label="
            $t(
              'modulos.participantes.formulario.participante_responsavel_faturamento'
            )
          "
          :multiplos="false"
          :disabled="!form.flagParticipanteConta"
        />
        <input-file
          v-if="form.flagParticipanteConta"
          :value="form.fileImagem"
          returns="base64"
          class="col-12 col-md-3"
          :label="
            $t('modulos.participantes.formulario.imagem_cabecalho_relatorio')
          "
          visualizador
          visualizador-em-cima
          :regras-personalizadas="[regraArquivo]"
          :image="form.imagemCabecalhoRelatorio"
          :disabled="!form.flagParticipanteConta"
          @handle-upload="handleUpload"
          @clear="desvincularImagem"
        />
        <input-participantes
          v-if="participanteLogadoConta"
          v-model="form.participanteResponsavel"
          class="col-12 col-md-3"
          :label="
            $t('modulos.participantes.formulario.participante_responsavel')
          "
          :multiplos="false"
          participante-conta
        />

        <input-radio
          v-if="participanteLogadoConta"
          v-model="form.flagParticipanteConta"
          :divider="false"
          class="col-12 col-md-2 mt-2"
          :options="opcoes.boleano"
          :label="$t('modulos.participantes.formulario.participante_conta')"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { InputParticipantes, InputFile } from '@components/inputs';
import { mapGetters } from 'vuex';
import helpers from '@common/utils/helpers';
import EnumeradorService from '@common/services/cadastros/EnumeradorService.js';
import UtilsService from '@common/services/UtilsService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
export default {
  components: { InputParticipantes, InputFile },
  props: {
    form: {},
  },
  data() {
    return {
      cnpj: {},
      hasFlagParticipanteConta: { type: Boolean },
      opcoes: {
        tipoParticipante: helpers.TipoParticipanteEnum,
        tipoPessoa: helpers.TipoPessoaEnum,
        papelFornecedor: helpers.PapelFornecedorEnum,
        diasEnvioMaterial: helpers.DiasEnvioMaterialEnum,
        validacaoCertificado: [],
        boleano: helpers.BoleanoEnum,
      },
      mascaraTel: '(##) #####-####',
      mascaraDoc: '',
      atualizacoes: 0,
      editarDocumento: false,
      participanteLogadoId: localStorage.getItem('participanteLogadoId'),
    };
  },
  computed: {
    ehCpf: function () {
      return this.form.tipoParticipante === 'PessoaFisica';
    },
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
  },
  watch: {
    form: {
      handler(form) {
        this.atualizacoes++;
        this.verificarTipoParticipanteRadio(form.tipoParticipanteRadio);
        if (this.atualizacoes > 1) {
          this.definirMascaraDocumento(form.tipoParticipante);
          this.mascaraTel = this.mascaraTelefone(this.form.telefone);
        }
      },
      deep: true,
    },
    'form.flagEnvioMaterialSegundaFeira': {
      handler(value) {
        if (value == null) this.form.flagEnvioMaterialSegundaFeira = false;
      },
    },
    'form.flagEnvioMaterialTercaFeira': {
      handler(value) {
        if (value == null) this.form.flagEnvioMaterialTercaFeira = false;
      },
    },
    'form.flagEnvioMaterialQuartaFeira': {
      handler(value) {
        if (value == null) this.form.flagEnvioMaterialQuartaFeira = false;
      },
    },
    'form.flagEnvioMaterialQuintaFeira': {
      handler(value) {
        if (value == null) this.form.flagEnvioMaterialQuintaFeira = false;
      },
    },
    'form.flagEnvioMaterialSextaFeira': {
      handler(value) {
        if (value == null) this.form.flagEnvioMaterialSextaFeira = false;
      },
    },
  },
  async mounted() {
    this.buscarEnumValidacaoCertificado();
    this.hasFlagParticipanteConta = await this.possuiFlagParticipanteConta();
  },
  methods: {
    possuiFlagParticipanteConta: async function () {
      const res = await ParticipanteService.buscar(this.participanteLogadoId);
      return res.data.flagParticipanteConta;
    },
    verificarTipoParticipanteRadio() {
      switch (this.form.tipoParticipanteRadio) {
        case 1:
          this.definirFlagsPeloTipoParticipante(true, false);
          break;
        case 2:
          this.definirFlagsPeloTipoParticipante(false, true);
          break;
        case 3:
          this.definirFlagsPeloTipoParticipante(true, true);
      }
    },
    limparDocumento: function () {
      this.mascaraDocumento = '';
      this.form.documento = undefined;
    },
    definirMascaraDocumento: function (v) {
      if (this.form.documento === undefined) return;
      if (v === 'PessoaJuridica') {
        this.mascaraDoc = '##.###.###/####-##';
        return;
      }
      if (v === 'PessoaFisica') this.mascaraDoc = '###.###.###-##';
    },
    definirFlagsPeloTipoParticipante(flagCliente, flagFornecedor) {
      this.form.flagCliente = flagCliente;
      this.form.flagFornecedor = flagFornecedor;
    },
    buscarEnumValidacaoCertificado: function () {
      EnumeradorService.buscar('EnumValidacaoCertificado').then((res) => {
        this.opcoes.validacaoCertificado = res;
      });
    },
    regraArquivo(v) {
      if (!v) return true;
      if (v?.type == 'image/png') return true;
      return this.$t(`modulos.colaborador.validacoes.permitido_png`);
    },
    handleUpload(file) {
      this.form.imagemCabecalhoRelatorio = file;
    },
    desvincularImagem() {
      this.form.imagemCabecalhoRelatorio = null;
      this.form.imagemCabecalhoRelatorio = null;
    },
    consultarCNPJ: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      const numeroCNPJ = this.form.documento;

      if (
        typeof this.form.documento === 'string' ||
        this.form.documento instanceof String
      ) {
        this.form.documento = this.form.documento.replace(/\D/g, '');
      } else {
        this.form.documento = String(this.form.documento).replace(/\D/g, '');
      }

      UtilsService.buscarCNPJ(this.form.documento)
        .then((res) => {
          this.cnpj = res.data;
          this.form.apelido = res.data.nomeFantasia;
          this.form.nome = res.data.nomeRazaoSocial;
          this.form.telefone = res.data.telefone;
          this.form.email = res.data.email;

          this.$emit('cnpj-atualizado', this.cnpj);
          this.toastSucesso(
            this.$t(`modulos.participantes.formulario.cnpj.cnpj_sucesso`)
          );

          this.form.documento = '';
        })
        .catch(() => {
          this.toastAlerta(
            this.$t(`modulos.participantes.erros.cnpj_invalido`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.form.documento = numeroCNPJ;
        });
    },
    confirmarBuscarCNPJ() {
      if (this.form.id) {
        this.confirmacaoBuscaCNPJ().then(() => {
          this.consultarCNPJ();
        });
      } else {
        this.consultarCNPJ();
      }
    },
    confirmacaoBuscaCNPJ: function () {
      return new Promise((resolve, reject) => {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t(`modulos.participantes.valores.alertas.confirmar_buscar_cnpj`)
        )
          .then(() => {
            this.$store.dispatch(
              'Layout/iniciarCarregamento',
              'Buscando dados na Receita Federal'
            );
            resolve();
          })
          .catch(reject);
      });
    },
  },
};
</script>
<style>
.papelFornecedoresCheckboxes {
  gap: 20px;
}
</style>
