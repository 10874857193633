<template>
  <v-form ref="form">
    <tabela-padrao-prime-vue
      ref="tabela"
      class="mt-2"
      linha-hover
      sem-acoes
      sem-paginacao
      ajustar-altura-linha
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      :por-pagina="-1"
      :mostrar-seletor="false"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:nome="{ slotProps }">
        <strong>{{ slotProps.data.nome }}
          <span v-if="slotProps.data.obrigatorio"> * </span>
        </strong>
      </template>
      <template v-slot:valor="{ slotProps }">
        <input-condicional-campos-adicionais
          v-model="buscarValorInput(slotProps.data).valorInput"
          :tipo-input="slotProps.data.tipoCampo"
          :opcoes="slotProps.data.itens"
          :obrigatorio="slotProps.data.obrigatorio"
        />
      </template>
      <template v-slot:valorPadrao="{ slotProps }">
        <span
          v-if="typeof slotProps.data.valorPadrao === 'boolean' ||
            slotProps.data.valorPadrao == 'true' || slotProps.data.valorPadrao == 'false'"
          class="primary-grey-400--text"
        >
          {{ slotProps.data.valorPadrao ?
            $t(`modulos.participantes.formulario.campos_adicionais.true`) :
            $t(`modulos.participantes.formulario.campos_adicionais.false`)
          }}
        </span>
        <span
          v-else
          class="primary-grey-400--text"
        >
          {{ slotProps.data.valorPadrao
            ? formatarValorPadrao(slotProps.data)
            : '' }}
        </span>
      </template>
    </tabela-padrao-prime-vue>
  </v-form>
</template>
<script>
import CampoAdicionalService from "@common/services/cadastros/CampoAdicionalService";
import InputCondicionalCamposAdicionais from "./inputs/InputCondicionalCamposAdicionais.vue";
import helpers from '@/common/utils/helpers';
import _ from "lodash";
export default {
  components: { InputCondicionalCamposAdicionais },
  props: {
    form: {},
  },
  data() {
    return {
      conteudo: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t(
              'modulos.participantes.formulario.campos_adicionais.nome_campo_adicional'
            ),
            sortable: false,
          },
          {
            value: 'valor',
            text: this.$t('modulos.participantes.formulario.campos_adicionais.valor_modelo'),
            sortable: false,
            width: 440,
          },
          {
            value: 'valorPadrao',
            text: this.$t('modulos.participantes.formulario.campos_adicionais.valor_padrao'),
            sortable: false,
            width: 380,
          },
        ],
      },
    };
  },
  watch: {
    conteudo: {
      handler() {
        this.form.camposAdicionais = this.conteudo;
        this.$emit("validarCamposAdicionais", this.$refs.form.validate() && this.validarCamposObrigatorios());
      },
      deep: true,
    },
  },
  methods: {
    clonarCounteudoForm(){
      let conteudoClonado = _.cloneDeep(this.form.camposAdicionais);
      if (conteudoClonado) {
        this.conteudo = conteudoClonado;
        this.listar();
      }
    },
    validarCampos: function(){
      this.$refs.form.validate()
    },
    validarCamposObrigatorios(){
      const camposObrigatorios = this.tabela.dados.filter(el => el.obrigatorio)
      const camposValidar = this.form.camposAdicionais.filter(ca =>
        camposObrigatorios.some(co => co.id == ca.value))

      return camposValidar.every(el => !!el.valorInput);
    },
    listar: function () {
      let parametros = {
        entidade: 2,
      };
      this.$store.dispatch("Layout/iniciarCarregamento");
      CampoAdicionalService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch("Layout/terminarCarregamento");
          this.validarCampos();
        });
    },
    buscarValorInput: function (item) {
      let obj = this.conteudo.find((conteudo) => conteudo.value == item.id);
      if (obj == null) {
        let valor = item.valorPadrao;
        if (valor && item.tipoCampo == "ListaMultiplaEscolha") {
          if (valor.includes(",")) {
            valor = valor.split(",");
          } else {
            valor = [valor];
          }
        }

        obj = {
          value: item.id,
          text: item.nome,
          valorInput: valor,
        };

        this.conteudo.push(obj);
      }
      obj.tipoCampo = item.tipoCampo;
      obj.edicaoValor = item.edicaoValor;

      if(obj.tipoCampo === "ListaMultiplaEscolha"){
        if(typeof(obj.valorInput) === "string")
          obj.valorInput = obj.valorInput.split(",");
      }
      return obj;
    },
    formatarValorPadrao: function (item) {
      if (item.tipoCampo == 'Data') {
        return helpers.formatarDataBr(item.valorPadrao);
      }
      return item.valorPadrao;
    },
  },
};
</script>
<style scoped>
.input-padrao {
  padding: 0px;
}

tr * {
  display: flex;
  min-height: 55px;
  align-items: center;
}
</style>
