<template>
  <div>
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="form.enderecos.length <= 0"
        class="mt-3"
        @click="abrirNovo"
      >
        {{ $t('modulos.participantes.formulario.enderecos.botoes.novo') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      :key="keyAtualiza"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="form.enderecos"
      sem-paginacao
      :mostrar-seletor="false"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>

    <endereco-modal ref="modal-endereco" @salvar-novo="salvarNovoEndereco" />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import EnderecoModal from './modais/EnderecoModal.vue';
import TabelaPadraoPrimeVue from '@components/tabelas/TabelaPadraoPrimeVue.vue';
export default {
  components: {
    EnderecoModal,
    TabelaPadraoPrimeVue,
  },
  props: {
    form: {},
    endereco: {},
  },
  data() {
    return {
      keyAtualiza: 1,
      enderecoAtual: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'tipoEndereco',
            text: this.$t('modulos.participantes.formulario.enderecos.tipo'),
            formatter: (v) => {
              return helpers.TipoEnderecoEnum.find((el) => el.value == v)?.text;
            },
            sortable: true,
          },
          {
            value: 'uf',
            text: this.$t('modulos.participantes.formulario.enderecos.uf'),
            sortable: true,
          },
          {
            value: 'municipio.nome',
            text: this.$t(
              'modulos.participantes.formulario.enderecos.municipio'
            ),
            sortable: true,
          },
          {
            value: 'logradouro',
            text: this.$t(
              'modulos.participantes.formulario.enderecos.logradouro'
            ),
            sortable: true,
          },
          {
            value: 'numero',
            text: this.$t('modulos.participantes.formulario.enderecos.numero'),
            sortable: true,
          },
          {
            value: 'complemento',
            text: this.$t(
              'modulos.participantes.formulario.enderecos.complemento'
            ),
            sortable: true,
          },
          {
            value: 'bairro',
            text: this.$t('modulos.participantes.formulario.enderecos.bairro'),
            sortable: true,
          },
          {
            value: 'cep',
            text: this.$t('modulos.participantes.formulario.enderecos.cep'),
            sortable: true,
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  watch: {
    endereco(novoEndereco) {
      if (novoEndereco && Object.keys(novoEndereco).length > 0) {
        this.salvarNovoEndereco(novoEndereco);
      }
    },
  },
  mounted() {
    if (this.endereco && Object.keys(this.endereco).length > 0) {
      this.salvarNovoEndereco(this.endereco);
    }
  },
  methods: {
    abrirNovo: function () {
      this.$refs['modal-endereco'].abrirModal(null, this.form.enderecos);
    },
    salvarNovoEndereco: function (form) {
      if (this.form.enderecos.length > 0) {
        this.form.enderecos = [];
      }
      const tiposEndereco = [
        'Sede',
        'Cobranca',
        'LocalEntrega',
        'LocalRetirada',
      ];
      tiposEndereco.forEach((tipo) => {
        this.form.enderecos.push({
          ...form,
          tipoEndereco: tipo,
        });
      });
      this.form.enderecos.forEach((e) => {
        e.cep = this.aplicarMascaraCEP(e.cep);
      });
      this.keyAtualiza++;
    },
    aplicarMascaraCEP(string) {
      return string.replace(/(\d{5})(\d{3})/, '$1-$2');
    },

    abrirEditar: function (item) {
      this.enderecoAtual = { ...item };

      let form = item;
      form.unidadeFederativaId = item.unidadeFederativa.id;
      form.unidadeFederativa = item.unidadeFederativa.nome;
      form.municipioId = item.municipio.id;
      form.municipio = item.municipio.nome;
      this.$refs['modal-endereco'].abrirModal(form, this.form.enderecos);
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.enderecos.splice(this.form.enderecos.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
