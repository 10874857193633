<template>
  <div>
    <input-text
      v-model="form.prazoEntregaAcordado"
      :class="{
        'col-12 col-md-2': participanteLogadoConta,
        'col-12 col-md-3': !participanteLogadoConta,
      }"
      :label="$t('modulos.participantes.formulario.prazo')"
      :placeholder="$t('modulos.participantes.formulario.prazo_dias')"
      apenas-numeros
    />
    <accordion-padrao
      :titulo="$t('modulos.participantes.formulario.requisitos.texto')"
      class="col-12 col-md-12 pt-0"
      alterar-cor
      cor-azul-texto
      obrigatorio
    >
      <tabela-padrao
        v-model="tabela_texto.selecionados"
        :dados="tabela_texto.dados"
        class="mt-2"
        css-pagination="mt-3"
        :colunas="tabela_texto.colunas"
      >
        <!-- eslint-disable vue/valid-v-slot-->
        <template v-slot:item.acoes="{ item }">
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <div>
              <botao-padrao
                text
                color="secondary"
                @click="abrirEditarTexto(item.item)"
              >
                {{ $t('geral.botoes.editar') }}
              </botao-padrao>
            </div>
          </dropdown-padrao>
        </template>
        <template v-slot:item.tipoRequisito="{ item }">
          <span>
            {{ $t(`modulos.participantes.formulario.requisitos.${item.item.tipoRequisito}`) }}
          </span>
        </template>
        <template v-slot:item.descricao="{ item }">
          <pre style="white-space: pre-wrap;">{{ item.item.descricao }}</pre>
        </template>
        <template v-slot:foot>
          <div
            style="position: absolute;"
            class="d-flex flex-row"
          >
            <botao-padrao
              class="mt-3 mb-3 mr-2"

              @click="abrirNovoTexto"
            >
              {{
                $t('modulos.participantes.formulario.requisitos.botoes.novo_requisito_texto')
              }}
            </botao-padrao>
            <botao-padrao
              class="mt-3 mb-3 mr-2"
              @click="abrirGerarNovoTexto"
            >
              {{
                $t('modulos.participantes.formulario.requisitos.botoes.gerar_requisito_texto')
              }}
            </botao-padrao>
            <botao-padrao
              v-if="podeExcluirTexto"
              outlined
              class="mt-3 mb-3"
              color="danger"
              @click="excluirTexto"
            >
              {{
                $t(
                  'modulos.participantes.formulario.requisitos.botoes.excluir_novo_texto'
                )
              }}
            </botao-padrao>
          </div>
        </template>
      </tabela-padrao>
    </accordion-padrao>
    <accordion-padrao
      :titulo="
        $t('modulos.participantes.formulario.requisitos.tipos_documentos')
      "
      class="col-12 col-md-12 pt-0"
      alterar-cor
      cor-azul-texto
      obrigatorio
    >
      <tabela-padrao
        v-model="tabela_tipos_documentos.selecionados"
        :dados="tabela_tipos_documentos.dados"
        class="mt-2"
        css-pagination="mt-3"
        :colunas="tabela_tipos_documentos.colunas"
      >
        <!-- eslint-disable vue/valid-v-slot-->
        <template v-slot:item.acoes="{ item }">
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <div>
              <botao-padrao
                text
                color="secondary"
                @click="abrirEditarTiposDocumentos(item.item)"
              >
                {{ $t('geral.botoes.editar') }}
              </botao-padrao>
            </div>
          </dropdown-padrao>
        </template>
        <template v-slot:item.tipoRequisito="{ item }">
          <span>
            {{
              $t(
                `modulos.participantes.formulario.requisitos.${item.item.tipoRequisito}`
              )
            }}
          </span>
        </template>
        <template v-slot:foot>
          <div
            style="position: absolute;"
            class="d-flex flex-row"
          >
            <botao-padrao
              class="mt-3 mb-3 mr-2"
              @click="abrirNovoTiposDocumentos"
            >
              {{
                $t(
                  'modulos.participantes.formulario.requisitos.botoes.novo_tipos_documentos'
                )
              }}
            </botao-padrao>
            <botao-padrao
              v-if="podeExcluirTiposDocumentos"
              outlined
              class="mt-3 mb-3"
              color="danger"
              @click="excluirTiposDocumentos"
            >
              {{
                $t(
                  'modulos.participantes.formulario.requisitos.botoes.excluir_novo_tipos_documentos'
                )
              }}
            </botao-padrao>
          </div>
        </template>
      </tabela-padrao>
    </accordion-padrao>

    <requisito-texto-modal
      ref="modal-requisitos-texto"
      @salvar-novo="salvarNovoRequisitoTexto"
    />
    <requisito-campos-adicionais-modal
      ref="modal-requisitos-campos-adicionais"
      @salvar-novo="salvarNovoRequisitoCamposAdicionais"
    />
    <requisito-tipos-documentos-modal
      ref="modal-requisitos-tipos-documentos"
      @salvar-novo="salvarNovoRequisitoTiposDocumentos"
    />
    <requisito-gerar-texto-modal
      ref="modal-gerar-requisitos-texto"
      @salvar-novo="salvarGerarNovoRequisitoTexto"
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import RequisitoTextoModal from './modais/RequisitoTextoModal';
import RequisitoCamposAdicionaisModal from './modais/RequisitoCamposAdicionaisModal';
import RequisitoTiposDocumentosModal from './modais/RequisitoTiposDocumentosModal';
import RequisitoGerarTextoModal from './modais/RequisitoGerarTextoModal';
import { mapGetters } from 'vuex';
export default {
  components: {
    RequisitoTextoModal,
    RequisitoCamposAdicionaisModal,
    RequisitoTiposDocumentosModal,
    RequisitoGerarTextoModal,
  },
  props: {
    form: {},
  },
  data() {
    return {
      tabela_campos_adicionais: {
        selecionados: [],
        dados: this.form.requisitosCamposAdicionais,
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'tipoRequisito',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.tipo_requisito'
            ),
            formatter: (v) => {
              return this.formataTipoRequisito(v)
            },
            sortable: true,
          },
          {
            value: 'campoAdicional.nome',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.campo_adicional'
            ),
            sortable: true,
          },
        ],
      },
      tabela_texto: {
        selecionados: [],
        dados: this.form.requisitosTexto,
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'tipoRequisito',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.tipo_requisito'
            ),
            formatter: (v) => {
              return this.formataTipoRequisito(v)
            },
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.descricao'
            ),
            formatter: (v) => {
              return this.formataDescricao(v)
            },
            sortable: true,
          },
        ],
      },
      tabela_tipos_documentos: {
        selecionados: [],
        dados: this.form.requisitosTiposDocumentos,
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'tipoRequisito',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.tipo_requisito'
            ),
            formatter: (v) => {
              return this.formataTipoRequisito(v)
            },
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.descricao'
            ),
            sortable: true,
          },
          {
            value: 'tipoDocumento.nome',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.tipo_documento'
            ),
            sortable: true,
          },
        ],
      },
      id: null,
    };
  },
  computed: {
    podeExcluirCamposAdicionais: function () {
      return this.tabela_campos_adicionais.selecionados.length > 0;
    },
    podeExcluirTexto: function () {
      return this.tabela_texto.selecionados.length > 0;
    },
    podeExcluirTiposDocumentos: function () {
      return this.tabela_tipos_documentos.selecionados.length > 0;
    },
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    formataTipoRequisito(v) {
      if (typeof v == 'string') return v;
      return helpers.TipoRequisitoEnum.find((el) => el.value == v)?.text;
    },
    formataDescricao(v){
       if (typeof v == 'string') {
        return `<pre style="white-space: pre-wrap;">${v}</pre>`;
      }
      return '';
    },
    abrirNovoTexto: function () {
      this.$refs['modal-requisitos-texto'].abrirModal();
    },
    abrirNovoCamposAdicionais: function () {
      this.$refs['modal-requisitos-campos-adicionais'].abrirModal();
    },
    abrirNovoTiposDocumentos: function () {
      this.$refs['modal-requisitos-tipos-documentos'].abrirModal();
    },
    salvarNovoRequisitoTexto: function (form) {
      form.id = helpers.gerarNumeroAleatorio();
      this.form.requisitosTexto.push(form);
    },
    salvarNovoRequisitoCamposAdicionais: function (form) {
      form.id = helpers.gerarNumeroAleatorio();
      this.form.requisitosCamposAdicionais.push(form);
    },
    salvarNovoRequisitoTiposDocumentos: function (form) {
      form.id = helpers.gerarNumeroAleatorio();
      this.form.requisitosTiposDocumentos.push(form);
    },
    abrirEditarTexto: function (item) {
      this.$refs['modal-requisitos-texto'].abrirModal(item);
    },
    abrirEditarCamposAdicionais: function (item) {
      this.$refs['modal-requisitos-campos-adicionais'].abrirModal(item);
    },
    abrirEditarTiposDocumentos: function (item) {
      this.$refs['modal-requisitos-tipos-documentos'].abrirModal(item);
    },
    excluirCampoAdicional: function () {
      this.confirmarExcluir().then(() => {
        this.tabela_campos_adicionais.selecionados.forEach((element) => {
          this.tabela_campos_adicionais.dados.splice(this.tabela_campos_adicionais.dados.indexOf(element), 1);
        });
        this.tabela_campos_adicionais.selecionados = [];
      });
    },
    excluirTexto: function () {
      this.confirmarExcluir().then(() => {
        this.tabela_texto.selecionados.forEach((element) => {
          this.tabela_texto.dados.splice(this.tabela_texto.dados.indexOf(element), 1);
        });
        this.tabela_texto.selecionados = [];
      });
    },
    excluirTiposDocumentos: function () {
      this.confirmarExcluir().then(() => {
        this.tabela_tipos_documentos.selecionados.forEach((element) => {
          this.tabela_tipos_documentos.dados.splice(this.tabela_tipos_documentos.dados.indexOf(element), 1);
        });
        this.tabela_tipos_documentos.selecionados = [];
      });
    },
    abrirGerarNovoTexto: function () {
      this.$refs['modal-gerar-requisitos-texto'].abrirModal();
    },
    salvarGerarNovoRequisitoTexto: function (form) {
      let filteredForm = form.filter(item => item.resposta !== 'nao_aplica');

      let formatterForm = filteredForm.flatMap(item =>
        item.tiposRequisito.map(tipo => ({
            id: helpers.gerarNumeroAleatorio(),
            descricao: item.requisito,
            tipoRequisito: tipo
        }))
      );
      this.agruparDescricoesPorTipo(formatterForm).forEach(obj => this.salvarNovoRequisitoTexto(obj))
    },
    agruparDescricoesPorTipo(requisitos) {
      const agrupado = requisitos.reduce((acc, requisito) => {
        if (!acc[requisito.tipoRequisito]) {
          acc[requisito.tipoRequisito] = {
            tipoRequisito: requisito.tipoRequisito,
            descricao: requisito.descricao
          };
        } else {
          acc[requisito.tipoRequisito].descricao += '\n\n' + requisito.descricao;
        }
        return acc;
      }, {});

      return Object.values(agrupado);
    }
  },
};
</script>
<style scoped >
  .descricao-formatada p {
    margin-bottom: 1em;
  }
  .descricao-formatada {
  white-space: pre-wrap;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
}
</style>
