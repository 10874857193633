<template>
  <v-form ref="formTabela">
    <v-simple-table dense class="mt-3 tabela-dados-procedimentos-eletronicos">
      <thead>
        <tr>
          <th
            v-for="(coluna, index) in tabela.colunas"
            :key="index"
            :width="coluna.width"
            scope="col"
          >
            {{ coluna.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in form" :key="index">
          <td>
            <div class="d-flex align-center">
              <span>{{ item.participante.text }}</span>
            </div>
          </td>
          <td>
            <input-setor
              v-model="item.setor"
              :multiplos="false"
              sem-label
              flat
              solo
              class="flex-fill input-tabela mt-2"
              background-color="transparent"
              :participante-id="item.participante.value"
            />
          </td>
          <td scope="col">
            <botao-padrao
              text
              color="secondary"
              @click="removerItemDaTabela(index)"
            >
              <v-icon>$mdiTrashCanOutline</v-icon>
              {{ $t('geral.botoes.excluir_linha') }}
            </botao-padrao>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="px-0">
            <botao-padrao
              text
              color="secondary"
              btn100
              @click="abrirModal"
            >
              <v-icon> $plus </v-icon>
              {{ $t('modulos.usuarios.formulario.adicionar_linha') }}
            </botao-padrao>
          </td>
        </tr>
      </tbody>
      <tbody />
    </v-simple-table>
    <modal-padrao
      ref="modal-input"
      :max-width="1000"
      :titulo="$t('componentes.input_participantes.titulo_modal')"
      :titulo-ok="$t('geral.botoes.confirmar')"
      @ok="aplicarSelecionados"
    >
      <modal
        :key="componentKey"
        v-model="participanteSelecionado"
        refs="modal"
        :multiplos="false"
        @fechar-modal-click-linha="aplicarSelecionados"
      />
    </modal-padrao>
  </v-form>
</template>
<script>
import InputSetor from '@/components/inputs/especificos/InputSetor';
import Modal from '@/components/inputs/especificos/InputParticipantes/modal.vue';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
  name: 'TabelaDadosProcedimento',
  components: { InputSetor, Modal },
  props: {
    form: {},
  },
  data() {
    return {
      componentKey: 0,
      participanteSelecionado: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.usuarios.formulario.participante'),
            sortable: false,
          },
          {
            value: 'setor',
            text: this.$t('modulos.usuarios.formulario.setor'),
            sortable: false,
          },
          {
            value: 'excluir',
            text: '',
            width: '80px',
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    adicionarLinha: function (participante) {
      this.form.push({ participante });
    },
    removerItemDaTabela: function (index) {
      this.form.splice(index, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      this.componentKey++;
      this.participanteSelecionado = [];
    },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      let participante = new DropdownModel(
        this.participanteSelecionado,
        'apelido'
      );

      if (
        this.form.some((item) => {
          return item.participante.value == participante.value;
        })
      ) {
        this.toastErro(
          this.$t(
            'modulos.procedimentos_eletronicos.erros.participante_selecionado'
          )
        );
        return;
      }
      if (Array.isArray(participante)) {
        !!participante.length && this.adicionarLinha(participante[0]);
        return;
      }

      this.adicionarLinha(participante);
    },
  },
};
</script>
<style lang="scss">
.tabela-dados-procedimentos-eletronicos {
  th {
    background-color: $color-primary-grey-100;
  }

  thead {
    height: 48px;
  }
}
</style>
