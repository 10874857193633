<template>
  <modal-padrao
    ref="modal-endereco"
    max-width="80%"
    :titulo="$t('modulos.participantes.formulario.enderecos.endereco')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="confirmacaoSalvar"
    @cancelar="fechar"
  >
    <v-form ref="form" class="row">
      <input-select
        v-model="form.tipoEndereco"
        class="col-12 col-md-3"
        :label="$t('modulos.participantes.formulario.enderecos.tipo')"
        :options="opcoes.tipoEndereco"
        :regras-personalizadas="[validarSede]"
        :disabled="!!form.tipoEndereco && !!form.cep"
        obrigatorio
      />
      <input-text
        v-model="form.cep"
        class="col-12 col-md-2"
        :label="$t('modulos.participantes.formulario.enderecos.cep')"
        :mascara="'#####-###'"
        obrigatorio
        @blur="buscarCep"
      />
      <input-text
        v-model="form.logradouro"
        class="col-12 col-md-5"
        :label="$t('modulos.participantes.formulario.enderecos.logradouro')"
        obrigatorio
      />
      <input-text
        v-model="form.numero"
        class="col-12 col-md-2"
        :label="$t('modulos.participantes.formulario.enderecos.numero')"
        obrigatorio
      />
      <input-text
        v-model="form.complemento"
        class="col-12 col-md-3"
        :label="$t('modulos.participantes.formulario.enderecos.complemento')"
      />
      <input-text
        v-model="form.bairro"
        class="col-12 col-md-3"
        :label="$t('modulos.participantes.formulario.enderecos.bairro')"
        obrigatorio
      />
      <input-text
        v-model="form.unidadeFederativa"
        class="col-12 col-md-2"
        :label="$t('modulos.participantes.formulario.enderecos.uf')"
        disabled
        obrigatorio
      />
      <input-text
        v-model="form.municipio"
        class="col-12 col-md-4"
        :label="$t('modulos.participantes.formulario.enderecos.municipio')"
        disabled
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import UtilsService from '@common/services/UtilsService';
import _ from 'lodash';
export default {

  data() {
    return {
      edicao: false,
      form: {},
      formCancelar: {},
      formReferencia: {},
      enderecos: {},
      opcoes: {
        tipoEndereco: helpers.TipoEnderecoEnum,
      },
    };
  },
  mounted() {

    this.iniciarForm();
  },
  methods: {
    fechar() {
      if (this.edicao) {
        this.formCancelar.unidadeFederativa = {
          id: this.formCancelar.unidadeFederativaId,
          nome: this.formCancelar.unidadeFederativa,
        };

        this.formCancelar.municipio = {
          id: this.formCancelar.municipioId,
          nome: this.formCancelar.municipio,
        };

        Object.entries(this.formCancelar).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-endereco'].fecharModal();
    },
    iniciarForm: function () {
      this.form = {
        id: null,
        tipoEndereco: this.opcoes.tipoEndereco[0],
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        unidadeFederativa: null,
        municipio: null,
        unidadeFederativaId: null,
        municipioId: null,
      };
    },
    abrirModal: function (form, enderecos) {
      this.enderecos = enderecos;
      this.iniciarForm();
      this.edicao = false;
      if (form) {
        this.edicao = true;
        this.formReferencia = form;
        this.form = _.cloneDeep(form);
        this.formCancelar = _.cloneDeep(form);
      }
      this.$refs['modal-endereco'].abrirModal();
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      this.form.unidadeFederativa = {
        id: this.form.unidadeFederativaId,
        nome: this.form.unidadeFederativa,
      };

      this.form.municipio = {
        id: this.form.municipioId,
        nome: this.form.municipio,
      };
      if (!this.edicao) this.$emit('salvar-novo', this.form);
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-endereco'].fecharModal();
    },
    validarSede(v) {
      const jaExisteSede = this.enderecos.some(
        (el) => el.tipoEndereco === 'Sede'
      );
      const endSede = this.enderecos.find((el) => el.tipoEndereco === 'Sede');
      if (jaExisteSede && v.value == 'Sede' && endSede.id != this.form.id)
        return this.$t('modulos.participantes.validacoes.ja_existe_sede');
      if (!jaExisteSede && v.value != 'Sede')
        return this.$t(
          'modulos.participantes.validacoes.primeiro_endereco_sede'
        );
      return true;
    },
    confirmacaoSalvar: function () {
      if (!this.$refs.form.validate()) return;
      if (this.edicao) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    buscarCep: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UtilsService.buscarCep(this.form.cep)
        .then((res) => {
          let {
            cidade,
            bairro,
            logradouro,
            uf,
            unidadeFederativaId,
            municipioId,
          } = res.data;
          this.form.bairro = bairro;
          this.form.logradouro = logradouro;
          this.form.municipio = cidade;
          this.form.municipioId = municipioId;
          this.form.unidadeFederativa = uf;
          this.form.unidadeFederativaId = unidadeFederativaId;
        })
        .catch(() => {
          this.toastAlerta(this.$t(`modulos.participantes.erros.cep_invalido`));
          this.iniciarForm();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
