import helpers from '../../utils/helpers';
import _ from 'lodash';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export class ParticipanteModel {
  constructor({
    id,
    nome,
    apelido,
    documento,
    tipoParticipante = 'PessoaJuridica',
    flagFornecedor = false,
    flagCliente = false,
    inscricaoEstadual,
    inscricaoMunicipal,
    telefone,
    email,
    site,
    flagLaboratorio = false,
    flagFabricante = false,
    flagTransportador = false,
    flagEnvioMaterialSegundaFeira = false,
    flagEnvioMaterialTercaFeira = false,
    flagEnvioMaterialQuartaFeira = false,
    flagEnvioMaterialQuintaFeira = false,
    flagEnvioMaterialSextaFeira = false,
    prazoEntregaAcordado,
    participanteResponsavelFaturamento,
    enderecos = [],
    requisitosTexto = [],
    requisitosCamposAdicionais = [],
    requisitosTiposDocumentos = [],
    contatos = [],
    camposAdicionais = [],
    validacaoCertificado = 'SemValidacao',
    flagParticipanteConta,
    imagemCabecalhoRelatorio,
    participanteResponsavel
  }) {
    this.id = id;
    this.nome = nome;
    this.apelido = apelido;
    this.documento = helpers.formatarDocumento(`${documento}`);
    this.tipoParticipante = tipoParticipante;
    this.flagFornecedor = flagFornecedor;
    this.flagCliente = flagCliente;
    this.inscricaoEstadual = inscricaoEstadual;
    this.inscricaoMunicipal = inscricaoMunicipal;
    this.telefone = helpers.formatarTelefone(`${telefone}`);
    this.email = email;
    this.site = site;
    this.flagLaboratorio = flagLaboratorio;
    this.flagFabricante = flagFabricante;
    this.flagTransportador = flagTransportador;
    this.flagEnvioMaterialSegundaFeira = flagEnvioMaterialSegundaFeira;
    this.flagEnvioMaterialTercaFeira = flagEnvioMaterialTercaFeira;
    this.flagEnvioMaterialQuartaFeira = flagEnvioMaterialQuartaFeira;
    this.flagEnvioMaterialQuintaFeira = flagEnvioMaterialQuintaFeira;
    this.flagEnvioMaterialSextaFeira = flagEnvioMaterialSextaFeira;
    this.prazoEntregaAcordado = prazoEntregaAcordado;
    this.participanteResponsavelFaturamento = participanteResponsavelFaturamento
      ? new DropdownModel(participanteResponsavelFaturamento)
      : null;
    this.enderecos = enderecos;
    this.contatos = contatos;
    this.camposAdicionais = camposAdicionais;
    this.validacaoCertificado = validacaoCertificado;
    this.tipoParticipanteRadio = 1;
    this.flagParticipanteConta = flagParticipanteConta;
    this.participanteResponsavel = participanteResponsavel ? new DropdownModel(participanteResponsavel) : null;

    if (flagCliente && !flagFornecedor) this.tipoParticipanteRadio = 1;

    if (!flagCliente && flagFornecedor) this.tipoParticipanteRadio = 2;

    if (flagCliente && flagFornecedor) this.tipoParticipanteRadio = 3;

    if (camposAdicionais)
      this.camposAdicionais = camposAdicionais.map((el) => {
        let valorInput = el.valor;
        if (el.tipoCampo == 'ListaMultiplaEscolha') {
          if (valorInput.includes(',')) {
            valorInput = valorInput.split(',');
          } else {
            valorInput = [valorInput];
          }
        }
        return {
          value: el.campoAdicional.id,
          text: el.campoAdicional.nome,
          valorInput: valorInput,
        };
      });

    this.enderecos = this.enderecos.map((el) => {
      if (el.id) el.flagJaExistente = true;
      else el.flagJaExistente = false;
      el.unidadeFederativaId = el.unidadeFederativa.id;
      el.municipioId = el.municipio.id;
      return el;
    });

    this.contatos?.forEach((contato) => {
      if (contato.id) contato.flagJaExistente = true;
      else contato.flagJaExistente = false;
      contato.responsabilidades = contato.responsabilidades?.map((r) => {
        return helpers.ContatoResponsabilidadeEnum.find(
          (el) => el.value === r.responsabilidade
        ).text;
      });
    });

    this.requisitosTexto = requisitosTexto;
    this.requisitosCamposAdicionais = requisitosCamposAdicionais;
    this.requisitosTiposDocumentos = requisitosTiposDocumentos;

    this.requisitosTexto?.forEach((el) => (el.IdOriginal = el.id));
    this.requisitosCamposAdicionais?.forEach((el) => {
      el.IdOriginal = el.id;
      el.campoAdicionalId = el.campoAdicional?.id;
    });
    this.requisitosTiposDocumentos?.forEach((el) => {
      el.IdOriginal = el.id;
      el.tipoDocumentoId = el.tipoDocumento?.id;
    });

    if (imagemCabecalhoRelatorio) {
      let ctx = this;
      this.imagemCabecalhoRelatorio = imagemCabecalhoRelatorio;
      helpers
        .converterBase64ParaFile(
          imagemCabecalhoRelatorio,
          'imagem-cabecalho.png',
          'image/png'
        )
        .then(function (file) {
          ctx.fileImagem = file;
        });
    }
  }

  removerIdFlagJaExistenteDosNovosItens(...args) {
    args.forEach((item) => {
      item.forEach((obj) => {
        if (!obj.flagJaExistente) {
          obj.id = null;
        }
      });
    });
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.telefone = helpers.removerMascara(this.telefone);
    retorno.documento = helpers.removerMascara(this.documento);
    retorno.contatos.forEach((contato) => {
      if (!contato.responsabilidades) contato.responsabilidades = [];
      contato.telefone = helpers.removerMascara(contato.telefone);
      const responsabilidades = [];
      contato.responsabilidades?.forEach((item) => {
        responsabilidades.push({
          responsabilidade: helpers.ContatoResponsabilidadeEnum.find(
            (el) => el.text === item
          ).value,
        });
        contato.responsabilidades = responsabilidades;
      });
      if (contato.usuario) {
        contato.usuario.participantes = contato.usuario?.participantes?.map(
          (el) => {
            if (el.participanteId) {
              return el;
            }
            return {
              participanteId: el.participante.value,
              setorId: el.setor?.value,
            };
          }
        );
      }
    });

    this.removerIdFlagJaExistenteDosNovosItens(retorno.enderecos);
    this.removerIdFlagJaExistenteDosNovosItens(retorno.contatos);

    retorno.camposAdicionais = [];
    retorno.camposAdicionais = this.camposAdicionais.reduce(
      (result, { value, valorInput, tipoCampo }) => {
        if (!valorInput) return result;
        let valor;
        if (tipoCampo == 'Data') {
          valor = valorInput.substr(0, 10);
        } else if (Array.isArray(valorInput)) {
          valor = valorInput.join(',');
        } else {
          valor = valorInput;
        }
        result.push({
          campoAdicionalId: value,
          valor: valor,
        });
        return result;
      },
      []
    );

    retorno.validacaoCertificado = this.validacaoCertificado ?? 'SemValidacao';
    retorno.participanteResponsavelFaturamentoId = this.flagParticipanteConta
      ? this.participanteResponsavelFaturamento?.value
      : null;

    retorno.requisitosTexto?.forEach((el) => {
      el.id = el.IdOriginal;
    });
    retorno.requisitosCamposAdicionais?.forEach((el) => {
      el.id = el.IdOriginal;
    });
    retorno.requisitosTiposDocumentos?.forEach((el) => {
      el.id = el.IdOriginal;
    });

    if (retorno.participanteResponsavel)
      retorno.participanteResponsavel = retorno.participanteResponsavel.value;
    
    return retorno;
  }
}
