<template>
  <modal-padrao
    ref="modal-gerar-requisitos-texto"
    max-width="80%"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo="
      $t('modulos.participantes.formulario.requisitos.gerar_requisito_texto')
    "
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="mt-10"
    >
      <div class="col-6 col-md-12">
        <tabela-padrao-prime-vue
          v-model="tabela.selecionados"
          :dados="tabela.dados"
          class="mt-2"
          :colunas="tabela.colunas"
          paginacao-em-memoria
          sem-paginacao
          :mostrar-acoes="false"
          :mostrar-seletor="false"
          :selecionar-colunas="false"
        >
          <template v-slot:resposta="{ slotProps }">
            <div class="row mr-2 ml-2">
              <input-radio
                v-model="slotProps.data.resposta"
                :divider="false"
                :options="opcoes.resposta"
                @input="formatarRequisito(slotProps, slotProps.data.resposta)"
              />
            </div>
          </template>
          <template v-slot:observacao="{ slotProps }">
            <input-text
              v-model="slotProps.data.observacao"
              :label="$t('modulos.orcamentos.formulario.observacao')"
              :sem-label="true"
              class="mt-2 mb-2"
              :disabled="slotProps.data.disabledObservacao"
              @input="formatarRequisito(slotProps, slotProps.data.resposta)"
            />
          </template>
          <template v-slot:requisito="{ slotProps }">
            <!-- <div v-html="slotProps.data.requisito" /> -->
            <input-textarea
              v-model="slotProps.data.requisito"
              :rows="2"
              class="col-12 col-md-12"
              :max="10000"
              :disabled="slotProps.data.disabledObservacao"
            />
          </template>
        </tabela-padrao-prime-vue>
      </div>
    </v-form>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import RequisitosService from '@common/services/cadastros/RequisitosService';
export default {
  data() {
    return {
      form: {},
      disabledObservacao: true,
      opcoes: {
        tipoRequisito: helpers.TipoRequisitoEnum,
        booleano: helpers.BoleanoEnum,
        resposta: [
          {
            text: this.$t('enums.resposta_requisito.sim'),
            value: 'sim',
            cor: 'primary-success-700',
          },
          {
            text: this.$t('enums.resposta_requisito.nao'),
            value: 'nao',
            cor: 'primary-error-700',
          },
          {
            text: this.$t('enums.resposta_requisito.nao_aplica'),
            value: 'nao_aplica',
            cor: 'primary-error-700',
          },
        ],
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.modal_gerar_requisito.nome'
            ),
          },
          {
            value: 'resposta',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.modal_gerar_requisito.resposta'
            ),
          },
          {
            value: 'observacao',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.modal_gerar_requisito.observacao'
            ),
          },
          {
            value: 'requisito',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.modal_gerar_requisito.requisito'
            ),
          },
        ],
      },
    };
  },
  mounted() {
    this.listarRequisitos();
  },
  methods: {
    iniciarForm() {
      if (this.dados) {
        this.form.respostas = this.dados.map((item) => ({
          nome: item.nome,
          valor: null,
        }));
      }
    },
    abrirModal: function () {
      this.listarRequisitos();
      this.$refs['modal-gerar-requisitos-texto'].abrirModal();
    },
    confirmacaoSalvar: function () {
      if (!this.$refs.form.validate()) return;

      this.tabela.dados = this.tabela.dados.filter(item => item.requisito)

      this.$emit('salvar-novo', this.tabela.dados);
      this.$refs['modal-gerar-requisitos-texto'].fecharModal();
    },
    listarRequisitos() {
      let parametros = {};

      this.$store.dispatch('Layout/iniciarCarregamento');
      RequisitosService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items.map(
            (item) =>
              (item = {
                ...item,
                nome: item.nome,
                resposta: null,
                observacao: null,
                requisito: '',
                tiposRequisito: item.tiposRequisito,
                disabledObservacao: true,
              })
          );
          this.iniciarForm();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    formatarRequisito(slotProps, item) {
      let requisito = '';

      if (item === 'sim') {
        slotProps.data.disabledObservacao = false;
        if (slotProps.data.observacao) {
          requisito = `${slotProps?.data.requisitoSim}\n  ${slotProps.data.textoRequisitoObservacao} ${slotProps.data.observacao}`;
        } else {
          requisito = slotProps?.data.requisitoSim;
        }
      } else if (item === 'nao') {
        slotProps.data.disabledObservacao = false;
        if (slotProps.data.observacao) {
          requisito = `${slotProps?.data.requisitoNao}\n  ${slotProps.data.textoRequisitoObservacao} ${slotProps.data.observacao}`;
        } else {
          requisito = slotProps?.data.requisitoNao;
        }
      } else {
        slotProps.data.disabledObservacao = true;
        requisito = null;
        this.tabela.dados[slotProps?.index].observacao = null
      }

      this.tabela.dados[slotProps?.index].requisito = requisito;
    },
  },
};
</script>
