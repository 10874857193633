<template>
  <div>
    <div v-if="!origem" class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form ref="form" :class="origem ? 'row mt-4' : 'row'">
      <input-text
        v-model="form.login"
        class="col-12 col-md-4"
        :label="$t('modulos.usuarios.formulario.login')"
        obrigatorio
        :max="100"
      />
      <input-text
        v-model="form.nome"
        class="col-12 col-md-4"
        :label="$t('modulos.usuarios.formulario.nome')"
        obrigatorio
        :max="100"
      />
      <input-select
        v-model="form.papelId"
        class="col-12 col-md-4"
        :label="$t('modulos.usuarios.formulario.papel')"
        :options="opcoes.papel"
        obrigatorio
      />
      <input-text
        v-model="form.email"
        class="col-12 col-md-4"
        :label="$t('modulos.usuarios.formulario.email')"
        :max="100"
        email
        :obrigatorio="!flagAdicionaParticipanteLogadoUsuario"
      />
      <input-text
        v-if="!form.id"
        v-model="form.senha"
        class="col-12 col-md-4"
        :label="$t('modulos.usuarios.formulario.senha')"
        :obrigatorio="!ehEdicao"
        :max="100"
        type="password"
      />
      <input-text
        v-if="!form.id"
        v-model="form.senhaConfirmacao"
        class="col-12 col-md-4"
        :label="$t('modulos.usuarios.formulario.confirmar_senha')"
        :obrigatorio="!ehEdicao || (form.senha != '' && form.senha != null)"
        :max="100"
        type="password"
        :regras-personalizadas="[validacaoConfirmacaoSenha]"
      />
      <input-text
        v-model="colaboradorNome"
        :class="!form.id ? 'col-12 col-md-4' : 'col-12 col-md-3'"
        :label="$t('modulos.usuarios.formulario.associado_colaborador')"
        disabled
      />
      <input-text
        v-model="contatoNome"
        :class="!form.id ? 'col-12 col-md-4' : 'col-12 col-md-3'"
        :label="$t('modulos.usuarios.formulario.associado_contato')"
        disabled
      />
      <input-file
        :value="form.fileImagem"
        accept="image/png"
        :class="!form.id ? 'col-12 col-md-4' : 'col-12 col-md-2'"
        returns="base64"
        :label="$t('modulos.usuarios.formulario.assinatura')"
        :regras-personalizadas="[regraArquivo]"
        @clear="desvincularImagem"
        @nome-imagem="nomeImagem"
        @handle-upload="handlerUpload"
      />
      <accordion-padrao
        :titulo="$t('modulos.usuarios.formulario.participantes_setor')"
        :nao-retrair="true"
        class="col-12"
      >
        <tabela-participante-setor
          :form="form.participantes"
        />
      </accordion-padrao>
    </v-form>

    <v-divider v-if="!origem" class="my-5" />
    <div v-if="!origem" class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import UsuarioService from '@common/services/cadastros/UsuarioService.js';
import SetorService from '@common/services/cadastros/SetorService.js';
import { UsuarioModel } from '@common/models/cadastros/UsuarioModel.js';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import TabelaParticipanteSetor from './TabelaParticipanteSetor.vue';
import PapelService from '@common/services/cadastros/PapelService.js';
import InputFile from '@/components/inputs/InputFile.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    TabelaParticipanteSetor,
    InputFile,
  },
  props: {
    id: { type: String, default: null },
    origem: { type: String, default: null },
    nomeColaboradorUsuario: { type: [String, undefined], default: null },
    nomeContatoUsuario: { type: [String, undefined], default: null },
    emailContatoUsuario: { type: [String, undefined], default: null },
    flagAdicionaParticipanteLogadoUsuario: { type: Boolean, default: false },
    flagAdicionaParticipanteLogadoContato: { type: Boolean, default: false },
    setorColaborador: { type: Object, default: () => {} },
    formUsuario: { type: Object, default: () => {} },
  },
  data() {
    return {
      valido: false,
      colaboradorNome: null,
      contatoNome: null,
      form: new UsuarioModel({}),
      opcoes: {
        perfil: [],
        setor: [],
        papel: [],
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.usuarios.titulos.editar');
      return this.$t('modulos.usuarios.titulos.novo');
    },
    ehEdicao: function () {
      return !!this.id;
    },
  },
  watch: {
    formUsuario() {
      if (this.formUsuario) this.form = this.formUsuario;
    },
    nomeColaboradorUsuario() {
      this.form.nome = this.nomeColaboradorUsuario;
    },
    nomeContatoUsuario() {
      this.form.nome = this.nomeContatoUsuario;
    },
    emailContatoUsuario() {
      this.form.email = this.emailContatoUsuario;
      this.form.emailConfirmacao = this.emailContatoUsuario;
    },
    form: {
      handler() {
        this.$emit('form-usuario', this.form);
      },
      deep: true,
    },
    origem: {
      handler() {
        this.form.perfil = this.origem;
      },
    },
    id() {
      if (this.id) this.buscar(this.id);
    },
  },
  mounted() {
    this.validaColaboradorUsuarioAdicionaParticipanteSetor();
    this.validaContatoUsuarioAdicionaParticipante();
    if (this.nomeColaboradorUsuario) {
      this.form.nome = this.nomeColaboradorUsuario;
    }
    if (this.nomeContatoUsuario) {
      this.form.nome = this.nomeContatoUsuario;
    }
    if (this.emailContatoUsuario) {
      this.form.email = this.emailContatoUsuario;
      this.form.emailConfirmacao = this.emailContatoUsuario;
    }
    this.buscarSetor();
    this.buscarPapel();
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Usuarios', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Usuarios', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    validaContatoUsuarioAdicionaParticipante() {
      if (
        this.flagAdicionaParticipanteLogadoContato &&
        this.$store.getters['Autenticacao/usuario'].participantes.length &&
        !this.id
      ) {
        this.$store.getters['Autenticacao/usuario'].participantes.forEach(
          (item) => {
            if (item.value == localStorage.getItem('participanteLogadoId')) {
              this.form.participantes.push({
                participante: {
                  value: item.value,
                  text: item.text,
                },
              });
            }
          }
        );
      }
    },
    validaColaboradorUsuarioAdicionaParticipanteSetor() {
      if (
        this.flagAdicionaParticipanteLogadoUsuario &&
        this.$store.getters['Autenticacao/usuario'].participantes.length &&
        this.setorColaborador &&
        !this.id
      ) {
        this.$store.getters['Autenticacao/usuario'].participantes.forEach(
          (item) => {
            if (item.value == localStorage.getItem('participanteLogadoId')) {
              this.form.participantes.push({
                participante: {
                  value: item.value,
                  text: item.text,
                },
                setor: {
                  value: this.setorColaborador.value,
                  text: this.setorColaborador.text,
                },
              });
            }
          }
        );
      }
    },
    desvincularImagem() {
      this.form.imagemAssinatura = null;
      this.form.nomeArquivoAssinatura = null;
    },
    nomeImagem: function (nomeImagem) {
      this.form.nomeArquivoAssinatura = nomeImagem ? nomeImagem : null;
    },
    handlerUpload: function (file) {
      this.form.imagemAssinatura = file;
    },
    regraArquivo(v) {
      if (!v) return true;
      if (v?.type == 'image/png') return true;
      return this.$t(`modulos.colaborador.validacoes.permitido_png`);
    },
    iniciarForm: function () {
      this.$refs.form.resetValidation();
      (this.form.nome = null),
        (this.form.id = null),
        (this.form.email = null),
        (this.form.emailConfirmacao = null),
        (this.form.senha = null),
        (this.form.senhaConfirmacao = null),
        (this.form.papelId = null),
        (this.form.perfil = null),
        (this.form.participantes = []),
        (this.form.login = null);
    },
    validacaoConfirmacaoEmail: function (v) {
      if (!v) return true;
      if (this.form.email != this.form.emailConfirmacao)
        return this.$t('modulos.usuarios.validacoes.email');
      return true;
    },
    validacaoConfirmacaoSenha: function (v) {
      if (!v) return true;
      if (this.form.senha != this.form.senhaConfirmacao)
        return this.$t('modulos.usuarios.validacoes.senha');
      return true;
    },
    buscarSetor: function () {
      SetorService.listar().then((res) => {
        this.opcoes.setor = new DropdownModel(res.data.items);
      });
    },
    buscarPapel: function () {
      PapelService.listar().then((res) => {
        this.opcoes.papel = new DropdownModel(res.data.items);
      });
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UsuarioService.buscar(this.id)
        .then((res) => {
          this.form = new UsuarioModel({...res.data, texto: 'apelido'});
          this.colaboradorNome = res.data.colaborador?.nome;
          this.contatoNome = res.data.contato?.nome;
          setTimeout(() => {
            this.form.emailConfirmacao = res.data.emailConfirmacao;
            this.form.senha = res.data.senha;
          }, 500);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.usuarios.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      UsuarioService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.usuarios.cadastro_sucesso`));
          this.$router.push({ name: 'usuario' });

          if (
            this.form.id == this.$store.state.Autenticacao.usuario.id &&
            this.form.participantes.length
          ) {
            this.form.participantes.forEach((item) => {
              if (
                item.participante.value ==
                localStorage.getItem('participanteLogadoId')
              ) {
                localStorage.setItem('usuarioSetorId', item.setor.value);
              }
            });
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'usuario' });
      });
    },
    validacaoForm: function () {
      this.valido =
        this.$refs.form.validate() && this.form.participantes.length > 0;
      this.$emit('validacao-usuario', this.valido);
    },
  },
};
</script>
