import _ from 'lodash';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export class UsuarioModel {
  constructor({
    id,
    nome,
    papelId,
    setorId,
    login,
    participantes = [],
    email,
    emailConfirmacao,
    senha,
    senhaConfirmacao,
    perfil = 'Colaborador',
    colaboradorId,
    participanteContatoId,
    imagemAssinatura,
    nomeArquivoAssinatura,
    colaborador,
    contato,
    enderecoIP,
    texto = 'nome'
  }) {
    this.id = id;
    this.nome = nome;
    this.email = email;
    this.emailConfirmacao = emailConfirmacao;
    this.senha = senha;
    this.senhaConfirmacao = senhaConfirmacao;
    this.papelId = papelId;
    this.setorId = setorId;
    this.perfil = perfil;
    this.colaboradorId = colaboradorId;
    this.colaborador = colaborador;
    this.contato = contato;
    this.participanteContatoId = participanteContatoId;
    this.participantes = participantes;
    this.enderecoIP = enderecoIP;
    if (nomeArquivoAssinatura)
      this.fileImagem = new File([''], nomeArquivoAssinatura, {
        type: 'image/png',
      });
    this.imagemAssinatura = imagemAssinatura;
    this.nomeArquivoAssinatura = nomeArquivoAssinatura;
    if (participantes) {
      this.participantes = participantes.map((p) => {
        return {
          participante: new DropdownModel(p.participante, texto),
          setor: p.setor != null ? new DropdownModel(p.setor) : null,
        };
      });
    }
    this.login = login;
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.participantes = this.participantes.map((el) => {
      return {
        participanteId: el.participante.value,
        setorId: el.setor?.value,
      };
    });
    return retorno;
  }
}
