<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div />
      <div class="d-flex">
        <botao-padrao
          class="mt-3 mb-3"
          @click="adicionaCriterioAprovacao"
        >
          {{ $t('modulos.participantes.novo_criterio_aprovacao') }}
        </botao-padrao>
      </div>
    </div>
    <v-data-table
      :key="keyAtualiza"
      :headers="tabela.colunas"
      :items="formCriterioAceitacao.criterios"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="numeroItem"
      show-expand
      dense
      hide-default-footer
      :no-data-text="$t('modulos.participantes.criterios_aprovacao.sem_dados')"
      :items-per-page="99"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.sequencia="{ item, index }">
        <div class="d-flex">
          <div>
            <dropdown-padrao
              text
              color="secondary"
              style="width: 100% !important"
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>

              <v-list-item-group style="overflow-y: auto">
                <v-list-item
                  class="d-flex min-height-drop-item"
                  @click="excluir(index)"
                >
                  <v-icon>$mdiTrashCanOutline</v-icon>
                  {{ $t('geral.botoes.excluir') }}
                </v-list-item>
              </v-list-item-group>
            </dropdown-padrao>
          </div>
          <div class="mt-2">
            {{ item.numeroItem }}
          </div>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.participantes.criterios_aprovacao.seq') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t(
                        'modulos.participantes.criterios_aprovacao.operador_logico'
                      )
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t(
                        'modulos.participantes.criterios_aprovacao.tipo_criterio'
                      )
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t(
                        'modulos.participantes.criterios_aprovacao.operador_rel'
                      )
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t('modulos.participantes.criterios_aprovacao.tipo_valor')
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t('modulos.participantes.criterios_aprovacao.valor_num')
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t('modulos.participantes.criterios_aprovacao.valor_perc')
                    }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{
                      $t(
                        'modulos.participantes.criterios_aprovacao.valor_logico'
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(criterio, index) in item.criteriosAprovacao"
                  :key="criterio.numeroItem"
                >
                  <td class="mr-2 col-1">
                    <div class="d-flex">
                      <dropdown-padrao
                        :drop-w100="false"
                        text
                        color="secondary"
                      >
                        <template #botao>
                          <v-icon> $dotsVertical </v-icon>
                        </template>
                        <v-list-item-group style="overflow-y: auto">
                          <v-list-item
                            class="d-flex min-height-drop-item"
                            @click="
                              excluirCriterioAprovacao(item.numeroItem, index)
                            "
                          >
                            {{ $t('geral.botoes.remover') }}
                          </v-list-item>
                        </v-list-item-group>
                      </dropdown-padrao>
                      <span class="mt-2">
                        {{ criterio.sequencia }}
                      </span>
                    </div>
                  </td>
                  <td class="col-1">
                    <input-select
                      v-model="criterio.operadorLogico"
                      :options="opcoes.operadorLogico"
                      class="mb-1"
                      background-color="white"
                    />
                  </td>
                  <td class="col-3">
                    <input-select
                      v-model="criterio.tipoCriterio"
                      :options="opcoes.tiposCriterio"
                      class="mb-1"
                      style="min-width: 400px"
                      background-color="white"
                    />
                  </td>
                  <td class="col-2">
                    <input-select
                      v-model="criterio.operadorRelacional"
                      :options="opcoes.operadorRelacional"
                      class="mb-1"
                      style="min-width: 90px"
                      background-color="white"
                    />
                  </td>
                  <td class="col-2">
                    <input-select
                      v-model="criterio.tipoValor"
                      :options="opcoes.tipoValor"
                      class="mb-1"
                      style="min-width: 90px"
                      background-color="white"
                      @input="tipoValorAlterado(criterio.tipoValor, criterio)"
                    />
                  </td>
                  <td class="col-1">
                    <input-money
                      v-model="criterio.valorNumerico"
                      style="min-width: 110px;height: 52px;"
                      :options="optionsInputMoney"
                      class="mb-1"
                      :disabled="criterio.tipoValorNumerico"
                    />
                  </td>
                  <td class="col-1">
                    <input-money
                      v-model="criterio.valorPercentual"
                      style="min-width: 110px;height: 52px;"
                      :options="optionsInputMoney"
                      class="mb-1"
                      :disabled="criterio.tipoValorPercentual"
                    />
                  </td>
                  <td class="col-1">
                    <input-select
                      v-model="criterio.valorLogico"
                      :options="opcoes.valorLogico"
                      class="mb-1"
                      background-color="white"
                      :disabled="criterio.tipoValorLogico"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="row">
            <div
              class="d-flex mt-4 mb-2 col-7"
              style="background-color: #e4e7ec"
            >
              <dropdown-padrao
                text
                color="secondary"
                :drop-w100="false"
              >
                <template #botao>
                  <div @click="adicionaCriterioItem(item.numeroItem)">
                    <v-icon> $plus </v-icon>
                    {{ $t('modulos.orcamentos.formulario.adicionar') }}
                  </div>
                </template>
              </dropdown-padrao>
            </div>
            <div
              class="d-flex mt-4 mb-2 col-5"
              style="background-color: #e4e7ec; padding-left: 100px"
            />
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  components: {},
  props: {
    formCriterioAceitacao: {},
  },
  data() {
    return {
      opcoes: {
        operadorLogico: [],
        tiposCriterio: [],
        operadorRelacional: [],
        tipoValor: [],
        valorLogico: [],
      },
      keyAtualiza: 1,
      keyAtualizaCriterio: 1,
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2,
      },
      expanded: [],
      renumeracaoItens: 1,
      singleExpand: false,
      valido: false,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'sequencia',
            text: this.$t('modulos.participantes.criterio_aprovacao'),
            sortable: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.buscaOperadorLogico();
    this.opcoes.valorLogico.push(
      {
        text: 'Sim',
        value: true,
      },
      {
        text: 'Não',
        value: false,
      }
    );
  },
  methods: {
    adicionaCriterioItem(numeroItem) {
      this.formCriterioAceitacao.criterios.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.criteriosAprovacao.push({
            numeroItem: item.criteriosAprovacao.length + 1,
            sequencia: item.criteriosAprovacao.length + 1,
            operadorLogico: null,
            tipoCriterio: null,
            operadorRelacional: null,
            tipoValor: null,
            tipoValorLogico: true,
            tipoValorNumerico: true,
            tipoValorPercentual: true,
            valorNumerico: null,
            valorPercentual: null,
            valorLogico: null,
          });
        }
      });
    },
    adicionaCriterioAprovacao() {
      this.formCriterioAceitacao.criterios.push({
        numeroItem: this.formCriterioAceitacao.criterios.length + 1,
        sequencia: this.formCriterioAceitacao.criterios.length + 1,
        criteriosAprovacao: [],
      });
      this.expanded.push({
        numeroItem: this.formCriterioAceitacao.criterios.length,
        sequencia: this.formCriterioAceitacao.criterios.length,
        criteriosAprovacao: [],
      })

      this.keyAtualiza++;
    },
    buscaOperadorLogico() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumOperadorLogico')
        .then((res) => {
          this.opcoes.operadorLogico = res;
        })
        .finally(() => {
          this.buscaTipoCriterio();
        });
    },
    buscaTipoCriterio() {
      EnumeradorService.buscar('EnumTipoCriterioAprovacaoAutomatica')
        .then((res) => {
          this.opcoes.tiposCriterio = res;
        })
        .finally(() => {
          this.buscaOperadorRelacional();
        });
    },
    buscaOperadorRelacional() {
      EnumeradorService.buscar('EnumOperadorRelacional')
        .then((res) => {
          this.opcoes.operadorRelacional = res;
        })
        .finally(() => {
          this.buscaTipoValor();
        });
    },
    buscaTipoValor() {
      EnumeradorService.buscar('EnumTipoValor')
        .then((res) => {
          this.opcoes.tipoValor = res;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'participantes' });
      });
    },
    excluir(index) {
      this.renumeracaoItens = 1;
      this.formCriterioAceitacao.criterios.splice(index, 1);
      this.formCriterioAceitacao.criterios.map((item) => {
        item.numeroItem = this.renumeracaoItens++;
      });

      this.expanded.splice(index, 1);
    },
    excluirCriterioAprovacao(numeroItem, index) {
      this.renumeracaoItens = 1;
      this.renumeracaoSequencia = 1;
      this.formCriterioAceitacao.criterios.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.criteriosAprovacao.splice(index, 1);
        }
      });
      this.formCriterioAceitacao.criterios.map((item) => {
        if (item.numeroItem == numeroItem) {
          item.criteriosAprovacao.map((criterio) => {
            criterio.numeroItem = this.renumeracaoItens++;
            criterio.sequencia = this.renumeracaoSequencia++;
          });
        }
      });
    },
    tipoValorAlterado(value, criterio) {
      criterio.valorPercentual = null;
      criterio.valorNumerico = null;
      criterio.valorLogico = null;

      switch (value) {
        case 'Logico':
          criterio.tipoValorLogico = false;
          criterio.tipoValorNumerico = true;
          criterio.tipoValorPercentual = true;
          break;
        case 'Numerico':
          criterio.tipoValorLogico = true;
          criterio.tipoValorNumerico = false;
          criterio.tipoValorPercentual = true;
          break;
        case 'Percentual':
          criterio.tipoValorLogico = true;
          criterio.tipoValorNumerico = true;
          criterio.tipoValorPercentual = false;
          break;
        case null:
          criterio.tipoValorLogico = true;
          criterio.tipoValorNumerico = true;
          criterio.tipoValorPercentual = true;
          break
      }

    },
  },
};
</script>
<style>
.th-simple-table {
  background-color: #d0d5dd;
  height: 15px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
