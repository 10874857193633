<template>
  <modal-padrao
    ref="modal-requisitos-tipos-documentos"
    max-width="80%"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo="$t('modulos.participantes.formulario.requisitos.tipos_documentos')"
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-select
        v-model="form.tipoRequisito"
        class="col-12 col-md-6"
        :label="
          $t('modulos.participantes.formulario.requisitos.tipo_requisito')
        "
        :options="opcoes.tipoRequisito"
        obrigatorio
      /><input-select
        v-model="tabela.selecionados"
        class="col-12 col-md-6"
        :label="
          $t('modulos.participantes.formulario.requisitos.tipo_documento')
        "
        :options="tabela.dados"
        obrigatorio
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12 col-md-12"
        :label="$t('modulos.participantes.formulario.requisitos.descricao')"
        obrigatorio
      />
      <!-- <input-text
        v-model="form.ocorrencia"
        class="col-12 col-md-3"
        :label="$t('modulos.participantes.formulario.requisitos.ocorrencia')"
      /> -->
      <!-- <botao-padrao
        outlined
        color="secondary"
        class="mt-9 col-2 col-md-2 align-end"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao> -->

      <!-- <tabela-padrao
        v-model="tabela.selecionados"
        :dados="tabela.dados"
        class="mt-2 col-12 col-md-6"
        selecionar-apenas-um
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        semPaginacao
      /> -->
    </v-form>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import TipoDocumentoService from '@common/services/cadastros/TipoDocumentoService';
import _ from 'lodash';
export default {
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.participantes.formulario.requisitos.tipo_documento'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      edicao: false,
      form: {},
      formReferencia: {},
      opcoes: {
        tipoRequisito: helpers.TipoRequisitoEnum,
      },
      filtroAplicado: { filter: '' },
    };
  },
  watch: {
    'tabela.selecionados'() {
      if (!this.tabela.selecionados) return;
      this.form.tipoDocumento = {};
      this.tabela.dados.map((item) => {
        if (item.value == this.tabela.selecionados) {
          this.form.tipoDocumento.nome = item?.text;
        }
      });

      this.form.tipoDocumentoId = this.tabela.selecionados;
      this.form.tipoDocumento.id = this.tabela.selecionados;
    },
  },
  mounted() {
    this.iniciarForm();
    this.listarRegistros();
  },
  methods: {
    listarRegistros: function () {
      let parametros = {
        page: 1,
        amountPerPage: 1000,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoDocumentoService.listar(parametros)
        .then((res) => {
          if (res.data.items.length) {
            res.data.items.forEach((item) => {
              this.tabela.dados.push({
                value: item.id,
                text: item.nome,
              });
            });
          }
          // this.tabela.dados = res.data.items;
          // this.tabela.quantidadeItens = res.data.items.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    iniciarForm: function () {
      this.form = {
        id: null,
        tipoRequisito: null,
        descricao: null,
      };
    },
    abrirModal: function (form) {
      this.iniciarForm();
      this.edicao = false;
      if (form) {
        this.edicao = true;
        this.formReferencia = form;
        this.form = _.cloneDeep(form);
      } else {
        this.tabela.selecionados = [];
      }
      this.$refs['modal-requisitos-tipos-documentos'].abrirModal();
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      if (!this.tabela.selecionados) {
        this.toastAlerta(
          this.$t(
            'modulos.participantes.formulario.requisitos.deve_marcar_tabela'
          )
        );
        return;
      }
      if (!this.edicao) this.$emit('salvar-novo', this.form);
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-requisitos-tipos-documentos'].fecharModal();
    },
    confirmacaoSalvar: function () {
      if (this.edicao) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
  },
};
</script>
