<template>
  <div>
    <div v-if="mostraTitulo" class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.participantes.formulario.abas.dados_participante') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.participantes.formulario.abas.enderecos') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.participantes.formulario.abas.contatos') }}
      </v-tab>
      <v-tab>
        {{
          $t(
            'modulos.participantes.formulario.abas.criterios_aprovacao_automatica'
          )
        }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.participantes.formulario.abas.campos_adicionais') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.participantes.formulario.abas.requisitos') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-participantes
          ref="dados-participantes"
          :form="form"
          @cnpj-atualizado="cnpj = $event"
        />
      </v-tab-item>
      <v-tab-item>
        <endereco :form="form" :endereco="cnpj.endereco" />
      </v-tab-item>
      <v-tab-item>
        <contatos :id="id" :form="form" />
      </v-tab-item>
      <v-tab-item>
        <criterios-aprovacao-automatica
          :form-criterio-aceitacao="formCriterioAceitacao"
        />
      </v-tab-item>
      <v-tab-item>
        <campos-adicionais
          ref="campos-adicionais"
          :form="form"
          @validarCamposAdicionais="validarCamposAdicionais"
        />
      </v-tab-item>
      <v-tab-item> <requisitos :form="form" /> </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import CriterioAprovacaoService from '@common/services/cadastros/CriterioAprovacaoService';
import DadosParticipantes from './components/DadosParticipantes';
import Endereco from './components/Endereco';
import Contatos from './components/Contatos';
import CriteriosAprovacaoAutomatica from './components/CriteriosAprovacaoAutomatica';
import Requisitos from './components/Requisitos';
import CamposAdicionais from './components/CamposAdicionais';
import { ParticipanteModel } from '@common/models/cadastros/ParticipanteModel';
import { CriteriosAprovacaoModel } from '@common/models/cadastros/CriteriosAprovacaoModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    DadosParticipantes,
    Endereco,
    Contatos,
    CriteriosAprovacaoAutomatica,
    Requisitos,
    CamposAdicionais,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    mostraTitulo: {
      type: Boolean,
      default: true,
    },
    formularioModal: {
      type: Boolean,
      default: false,
    },
    clienteId: {
      type: String,
      default: '',
    },
    idParticipanteClonar: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validoCriterioAprovacao: false,
      camposAdicionaisValido: false,
      criterioAprovacaoHabilitadoRequest: false,
      indiceAba: 4,
      form: new ParticipanteModel({}),
      formCriterioAceitacao: new CriteriosAprovacaoModel({
        criterios: [],
      }),
      cnpj: { type: Object, default: {} },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.participantes.titulos.editar');
      return this.$t('modulos.participantes.titulos.novo');
    },
  },
  watch: {
    async clienteId(id) {
      await this.buscar(id);
      await this.buscarCriteriosAprovacao(id);
      this.camposAdicionaisValido = true;
      this.formCriterioAceitacao.clienteId = id;
      this.indiceAba = this.clienteId ? 2 : 0;
    },
    formCriterioAceitacao: {
      handler() {
        if (this.formCriterioAceitacao?.criterios?.length > 0) {
          this.formCriterioAceitacao?.criterios?.map((item) => {
            if (item.criteriosAprovacao?.length == 0) {
              this.validoCriterioAprovacao = false;
            } else {
              item.criteriosAprovacao?.map((itemCriterio) => {
                if (
                  !itemCriterio.tipoCriterio ||
                  !itemCriterio.operadorRelacional ||
                  !itemCriterio.tipoValor ||
                  !itemCriterio.operadorLogico
                ) {
                  this.validoCriterioAprovacao = false;
                  return;
                }
                if (
                  itemCriterio.tipoValor == 'Numerico' &&
                  !itemCriterio.valorNumerico
                ) {
                  this.validoCriterioAprovacao = false;
                  return;
                }
                if (
                  itemCriterio.tipoValor == 'Logico' &&
                  itemCriterio.valorLogico == null
                ) {
                  this.validoCriterioAprovacao = false;
                  return;
                }
                if (
                  itemCriterio.tipoValor == 'Percentual' &&
                  !itemCriterio.valorPercentual
                ) {
                  this.validoCriterioAprovacao = false;
                  return;
                }
                this.validoCriterioAprovacao = true;
                this.criterioAprovacaoHabilitadoRequest = true;
              });
            }
          });
        }
      },
      deep: true,
    },
    form: {
      handler() {
        if (this.formCriterioAceitacao?.criterios?.length == 0)
          this.validoCriterioAprovacao = true;
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.indiceAba = 0;
    if (this.id || this.clienteId) {
      let id = this.id ? this.id : this.clienteId;
      await this.buscar(id);
      await this.buscarCriteriosAprovacao(id);
      this.camposAdicionaisValido = true;
      this.formCriterioAceitacao.clienteId = id;
    }
    if (!this.id) {
      this.form.flagParticipanteConta = false;
    }
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Participante', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Participante', 'Inserir');

    this.indiceAba = this.clienteId ? 2 : 0;
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);

    !this.id && this.$refs['campos-adicionais']?.clonarCounteudoForm();
    this.idParticipanteClonar && this.buscar(this.idParticipanteClonar, true);
  },
  methods: {
    validarCamposAdicionais: function (camposAdicionaisValidacao) {
      this.camposAdicionaisValido = camposAdicionaisValidacao;
    },
    buscar: async function (id, clonarParticipante = false) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ParticipanteService.buscar(id)
        .then((res) => {
          this.form = new ParticipanteModel(res.data);
          if (clonarParticipante) this.limparDadosParaClonagem();

          this.form.contatos?.map((item) => {
            if (item.usuario?.email) {
              item.usuario.emailConfirmacao = item.usuario.email;
              item.usuario.senhaConfirmacao = item.usuario.senha;
            }
          });
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.participantes.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$refs['campos-adicionais']?.clonarCounteudoForm();
        });
    },
    limparDadosParaClonagem() {
      this.form.id = null;
      this.form.camposAdicionais = [];
      this.form.requisitosTexto = [];
      this.form.requisitosCamposAdicionais = [];
      this.form.requisitosTiposDocumentos = [];

      this.form.contatos?.forEach((contato) => {
        contato.usuario = null;
        contato.id = null;
      });
      this.form.enderecos?.forEach((endereco) => {
        endereco.id = null;
      });
    },
    buscarCriteriosAprovacao: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await CriterioAprovacaoService.buscar(this.id)
        .then((res) => {
          if (res.data) {
            this.formCriterioAceitacao = new CriteriosAprovacaoModel(res.data);
            this.formCriterioAceitacao.clienteId = res.data.cliente?.id;
            this.formCriterioAceitacao.criterios.map((item) => {
              this.formCriterioAceitacao.id = item.id;
              item.numeroItem = item.sequencia;
            });
            this.formCriterioAceitacao.criterios.sort(
              (a, b) => a.sequencia - b.sequencia
            );
          }
        })
        .catch(() => {
          this.toastAlerta(
            this.$t(
              'modulos.participantes.erros.participante_sem_criterios_aceitacao'
            )
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.validarParticipante()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validarParticipante: function () {
      if (this.form.enderecos.length == 0) {
        this.indiceAba = 1;
        this.toastAlerta(
          this.$t(`modulos.participantes.erros.endereco_preencher`)
        );
        return false;
      }
      if (!this.camposAdicionaisValido) {
        this.indiceAba = 4;
        this.toastAlerta(
          this.$t(`modulos.participantes.erros.verificar_campos_adicionais`)
        );
        return false;
      }

      if (!this.form.enderecos.some((el) => el.tipoEndereco == 'Sede')) {
        this.indiceAba = 1;
        this.toastAlerta(
          this.$t('modulos.participantes.validacoes.endereco_sede')
        );
        return false;
      }
      if (
        this.form.enderecos.filter((el) => el.tipoEndereco == 'Sede').length !=
        1
      ) {
        this.indiceAba = 1;
        this.toastAlerta(
          this.$t('modulos.participantes.validacoes.um_endereco_sede')
        );
        return false;
      }

      return true;
    },
    salvar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      await ParticipanteService.salvar(this.form.request)
        .then(async (res) => {
          if (this.criterioAprovacaoHabilitadoRequest) {
            if (!this.id) {
              this.formCriterioAceitacao.clienteId = res.data;
            }
            await this.salvarCriterioAprovacao();
          }
          this.toastSucesso(this.$t(`modulos.participantes.cadastro_sucesso`));
          this.voltar(res);
        })
        .catch((err) => {
          if (
            err.response.data.erros[0] ==
            'participanteResponsavelJaTemUmParticipanteResponsavelRelacionado'
          )
            this.toastAlerta(
              this.$t('modulos.participantes.erros.participante_responsavel')
            );
          else
            this.toastErro(
              this.$t(`geral.erros.${err.response.data.errors[0]}`)
            );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvarCriterioAprovacao() {
      return CriterioAprovacaoService.salvar(this.formCriterioAceitacao)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.participantes.cadastro_sucesso`));
          this.$router.push({ name: 'participantes' });
        })
        .catch((err) => {
          if (err.response.data.errors[0] == 'clienteJaPossuiCriterioAprovacao')
            this.toastAlerta(
              this.$t(`geral.erros.cliente_possui_criterios_aprovacao`)
            );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    voltar(res) {
      if (!this.formularioModal) {
        this.$router.push({ name: 'participantes' });
      } else {
        this.$emit(
          'fecha-modal-criacao-cliente',
          res?.data ? { id: res.data, nome: this.form.nome } : null
        );
      }
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.voltar();
      });
    },
  },
};
</script>
