<template>
  <v-form ref="form" class="row">
    <input-text
      v-model="form.nome"
      class="col-12 col-md-4"
      :label="$t('modulos.participantes.formulario.contatos.nome')"
      obrigatorio
    />
    <input-text
      v-model="form.setor"
      class="col-12 col-md-4"
      :label="$t('modulos.participantes.formulario.contatos.setor')"
      obrigatorio
    />
    <input-text
      v-model="form.cargo"
      class="col-12 col-md-4"
      :label="$t('modulos.participantes.formulario.contatos.cargo')"
      obrigatorio
    />
    <input-text
      ref="telefone"
      v-model="form.telefone"
      :key="keyAtualizaTelefone"
      class="col-12 col-md-4"
      :label="$t('modulos.participantes.formulario.contatos.telefone')"
      :mascara="mascaraTelefone(form.telefone)"
    />
    <input-text
      v-model="form.email"
      class="col-12 col-md-8"
      :label="$t('modulos.participantes.formulario.contatos.email')"
      obrigatorio
      email
    />
    <input-combobox-text
      v-model="form.responsabilidades"
      class="col-12 col-md-12"
      :label="$t('modulos.participantes.formulario.contatos.responsabilidade')"
      :options="opcoes.responsabilidades"
      multiplos
      somente-combobox
      :item-value="'text'"
      append-icon="$downArrow"
      somenteComboBox
    />
    <v-checkbox
      v-model="usuarioSistema"
      class="col-12 col-md-12"
      :label="$t('modulos.participantes.formulario.contatos.usuario_sistema')"
      hide-details
    />
  </v-form>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    form: {},
    id: { type: String, default: null },
    valorUsuario: { type: Boolean, default: null },
    status: { type: String, default: null },
  },
  data() {
    return {
      usuarioSistema: false,
      keyAtualizaTelefone: 1,
      valido: false,
      formReferencia: {},
      opcoes: {
        responsabilidades: [],
      },
    };
  },
  watch: {
    form() {
      this.validaUsuarioSistema();
      this.keyAtualizaTelefone++;
    },
    usuarioSistema() {
      this.$emit('usuario', this.usuarioSistema);
    },
  },
  mounted() {
    this.validaUsuarioSistema();
    this.responsabilidadesSelect();
  },
  methods: {
    validaUsuarioSistema() {
      if (this.form.usuario?.login) {
        this.usuarioSistema = true;
        this.$emit('usuario', true);
      } else {
        this.usuarioSistema = false;
        this.$emit('usuario', false);
      }
    },
    responsabilidadesSelect() {
      this.opcoes.responsabilidades = helpers.ContatoResponsabilidadeEnum;
    },
    corrigirBugMascaraTelefone() {
      if (!this.edicao)
        this.$nextTick().then(() => (this.form.telefone = null));
      else this.form.telefone = helpers.formatarTelefone(this.form.telefone);
    },
    validacaoForm: function () {
      this.valido = this.$refs.form.validate();
      this.$emit('validacao-contato', this.valido);
    },
    iniciarForm: function () {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
