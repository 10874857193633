<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          outlined
          class="mt-3"
          color="danger"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao class="mt-3" @click="abrirModal('', 'cadastro')">
        {{ $t('modulos.participantes.formulario.contatos.botoes.novo') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="form.contatos"
      :mostrar-acoes="true"
      sem-paginacao
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="10"
      :quantidade-paginas="tabela.quantidadePaginas"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao
              text
              color="secondary"
              @click="abrirModal(slotProps.data, 'edicao')"
            >
              {{ $t('geral.botoes.editar') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <contato-modal
      :id="id"
      ref="modal-contato"
      @salvar-novo="salvarNovoContato"
    />
  </div>
</template>
<script>
import ContatoModal from './modais/ContatoModal';
import helpers from '@common/utils/helpers';
import TabelaPadraoPrimeVue from '@components/tabelas/TabelaPadraoPrimeVue.vue';

export default {
  components: {
    ContatoModal,
    TabelaPadraoPrimeVue,
  },
  props: {
    form: {},
    id: { type: String, default: '' },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.participantes.formulario.contatos.nome'),
            sortable: true,
          },
          {
            value: 'telefone',
            text: this.$t('modulos.participantes.formulario.contatos.telefone'),
            sortable: true,
            formatter: (v) => {
              return helpers.formatarTelefone(v);
            },
          },
          {
            value: 'email',
            text: this.$t('modulos.participantes.formulario.contatos.email'),
            sortable: true,
          },
          {
            value: 'setor',
            text: this.$t('modulos.participantes.formulario.contatos.setor'),
            sortable: true,
          },
          {
            value: 'cargo',
            text: this.$t('modulos.participantes.formulario.contatos.cargo'),
            sortable: true,
          },
          {
            value: 'usuario.login',
            text: this.$t(
              'modulos.participantes.formulario.contatos.usuario_sistema'
            ),
            sortable: true,
          },
        ],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  methods: {
    salvarNovoContato: function (form) {
      form.perfil = 'Colaborador';
      this.form.contatos.push(form);
    },
    abrirModal: function (item, status) {
      this.$refs['modal-contato'].abrirModal(item, status);
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.contatos.splice(this.form.contatos.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
