<template>
  <modal-padrao
    ref="modal-contato"
    max-width="85%"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo="tituloModal"
    @ok="confirmacaoSalvar"
  >
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.participantes.formulario.abas.dados_contato') }}
      </v-tab>
      <v-tab
        :disabled="!(usuarioSistema && $refs['dados'].$refs.form.validate())"
      >
        {{ $t('modulos.participantes.formulario.abas.usuario_sistema') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <formulario-contato
          :id="id"
          ref="dados"
          class="mt-4"
          :form="form"
          :valido="validoContato"
          :status="status"
          @usuario="usuarioValor"
          @validacao-contato="validacaoContato"
        />
      </v-tab-item>
      <v-tab-item>
        <formulario-usuario
          ref="usuario"
          :origem="origem"
          :formUsuario="dadosUsuario"
          :valido-usuario="validoUsuario"
          @form-usuario="addFormUsuario"
          @validacao-usuario="validacaoUsuario"
          :id="usuarioId"
          :nomeContatoUsuario="nomeContatoUsuario"
          :emailContatoUsuario="emailContatoUsuario"
          :flagAdicionaParticipanteLogadoContato="
            flagAdicionaParticipanteLogadoContato
          "
        />
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="my-5" />
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import _ from 'lodash';
import FormularioContato from './FormContatoModal.vue';
import FormularioUsuario from '../../../usuario/formulario.vue';
export default {
  components: { FormularioContato, FormularioUsuario },
  props: {
    id: { type: String, default: null },
  },
  data() {
    return {
      status: null,
      dadosUsuario: null,
      flagAdicionaParticipanteLogadoContato: false,
      nomeContatoUsuario: null,
      emailContatoUsuario: null,
      usuarioSistema: false,
      usuarioId: null,
      origem: 'Colaborador',
      indiceAba: 0,
      validoUsuario: false,
      validoContato: false,
      form: {},
      formReferencia: {},
      opcoes: {
        responsabilidades: [],
      },
    };
  },
  computed: {
    tituloModal: function () {
      return this.$t('modulos.participantes.formulario.contatos.contato');
      // if (!this.form.nome) {
      //   return this.$t(
      //     'modulos.participantes.formulario.contatos.titulo_modal_novo'
      //   );
      // } else {
      //   return this.$t(
      //     'modulos.participantes.formulario.contatos.titulo_modal_editar'
      //   );
      // }
    },
  },
  mounted() {
    this.flagAdicionaParticipanteLogadoContato = true;
    this.responsabilidadesSelect();
  },
  watch: {
    'form.nome'() {
      this.nomeContatoUsuario = this.form.nome;
    },
    'form.email'() {
      this.emailContatoUsuario = this.form.email;
    },
  },
  methods: {
    iniciarForm: function () {
      (this.indiceAba = 0), this.$refs['usuario']?.iniciarForm();
      this.$refs['dados']?.iniciarForm();
      this.form = {
        id: helpers.gerarNumeroAleatorio(),
        nome: null,
        setor: null,
        cargo: null,
        telefone: null,
        email: null,
        usuario: null,
        usuarioSistema: null,
      };
    },
    responsabilidadesSelect() {
      this.opcoes.responsabilidades = helpers.ContatoResponsabilidadeEnum;
    },
    abrirModal: async function (form, status) {
      this.usuarioId = null;
      this.indiceAba = 0;
      this.status = status;
      if (status == 'cadastro') this.iniciarForm();
      if (form) {
        this.formReferencia = form;
        this.form = _.cloneDeep(form);
        this.form.perfil = 'Colaborador';
        this.dadosUsuario = this.form.usuario;
        if (this.form.usuario?.id) {
          this.validoUsuario = true;
          this.usuarioId = this.form.usuario?.id;
        }
      }
      this.$refs['modal-contato'].abrirModal();
      if (this.$refs.form) this.valido = this.$refs.form.resetValidation();
      this.corrigirBugMascaraTelefone();
    },
    corrigirBugMascaraTelefone() {
      if (this.status == 'cadastro') {
        this.$nextTick().then(() => (this.form.telefone = null));
      } else {
        this.form.telefone = helpers.formatarTelefone(this.form.telefone);
      }
    },
    salvar: function () {
      if (this.status == 'cadastro') this.$emit('salvar-novo', this.form);
      if (this.status == 'edicao') {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
      }
      this.$refs['modal-contato'].fecharModal();
    },
    confirmacaoSalvar: function () {
      this.$refs['dados'].validacaoForm();
      if (this.usuarioSistema) this.$refs['usuario']?.validacaoForm();

      if (!this.validoContato) return;
      if (this.usuarioSistema && !this.validoUsuario) {
        if (this.indiceAba == 0) return (this.indiceAba = 1);
        this.form.usuario.participantes.length <= 0
          ? this.toastAlerta(
              this.$t(`geral.erros.participanteDeveSerInformado`)
            )
          : this.toastAlerta(this.$t(`geral.erros.preenchaDadosdoUsuario`));
        return;
      }
      if (this.status == 'edicao') {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    usuarioValor(v) {
      this.usuarioSistema = v;
    },
    addFormUsuario(formUsuario) {
      this.form.usuario = {
        ...formUsuario,
        perfil: 'Colaborador',
        flagAdministrador: false,
        colaboradorId: null,
        participanteContatoId: null,
      };
    },
    validacaoContato: function (valido) {
      this.validoContato = valido;
    },
    validacaoUsuario: function (valido) {
      this.validoUsuario = valido;
    },
  },
};
</script>
